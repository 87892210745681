import React from 'react';
import Layouts from 'layouts';
import PropTypes from 'prop-types';
import { PromotionDetail33deposit100receive300 } from 'screens';
import { useIntl } from 'gatsby-plugin-intl';
import OgImage from 'images/promotions/promotion-cover--008.jpg';

const PromotionsPage = (props) => {
  const intl = useIntl();
  const { location } = props;

  return (
    <Layouts
      title={intl.formatMessage({ id: 'page.home.title' })}
      description={intl.formatMessage({ id: 'page.home.description' })}
      image={OgImage}
      pathname={location.pathname}
    >
      <PromotionDetail33deposit100receive300 />
    </Layouts>
  );
};

PromotionsPage.propTypes = {
  location: PropTypes.string.isRequired,
};

export default PromotionsPage;
